/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import "./style.css";

import {
  Row,
  Col,
  Table,
  Button,
  Typography,
  InputNumber,
  Select,
  Form,
  Spin,
  message,
} from "antd";

import { duplicateBilletApi } from "../../../../services/duplicateBillet";
import { NegotiationContext } from "../../../../Context/negotiation-context";
import { formtDate } from "../../../../utils/formatDateTime";

import CardTotal from "../../../CardTotal";
import moment from "moment";

const { Option } = Select;

const descountYears = {
  // "01/31/2018": {
  //   description: "Desconto REF a parcelas prescritas até 2018",
  //   discountPercentage: 80,
  //   active: true,
  //   discountGroup: ['C', 'CF', 'FF'],
  // },
};

function Negotiation(props) {
  const [loading, setLoading] = useState(false);
  const [paymentType, setPaymentType] = useState({
    value: "",
    description: "",
    maxPercent: 0,
  });
  const [descount, setDescount] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [installments, setInstallments] = useState([]);
  const [amount, setAmount] = useState(0);
  const [amountWithDiscount, setAmountWithDiscount] = useState(0);
  const [buttonNextDisabled, setButtonNextDisabled] = useState(true);
  const [footerObs, setFooterObs] = useState(false);
  const [installmentSelected, setInstallmentSelected] = useState(1);

  const negotiationContext = useContext(NegotiationContext);

  const [negotiationForm] = Form.useForm();

  const signin = useSelector((state) => state.signin);

  useEffect(() => {
    setDescountInstallments();
  }, [descount, paymentType]);

  useEffect(() => {
    setDescountInstallments();
  }, []);

  const setDescountInstallments = async () => {
    var total = 0;
    var totalWithDiscount = 0;

    setFooterObs(false);
    await setAmount(0);
    await setAmountWithDiscount(0);

    const newInstallments =
      await negotiationContext.data.contract.installmentsSelected.map(
        (installment) => {
          var descountInstallment = {
            description: "",
            discountPercentage: 0,
          };


          const dataInstallment = new moment(installment.VENCORIG);
          var descountYear = null;

          Object.keys(descountYears).forEach((item) => {  
            if (dataInstallment <= moment(item) && descountYears[item].active) {
              if (descountYears[item].discountGroup.find(element => element === negotiationContext.data.contract.TIPOCONTRT.trim())) {
                descountYear = descountYears[item];
                return;
              }
            }
          })
          //Caso o desconto for por ano da parcela
          if (descountYear) {
            descountInstallment = descountYear;
            setFooterObs(true);
          } else if (installment.VALOR_ENCARGOS > 0 && descount) {
            if (negotiationContext.groupFinancial || descount <= paymentType.maxPercent) {
              descountInstallment = {
                description: `Desconto pgto via ${paymentType.description}`,
                discountPercentage: descount,
              };
            }
          }

          var valueDiscount =
            (installment.VALOR_ENCARGOS *
              descountInstallment.discountPercentage) /
            100;
          var installmentWithDiscount =
            installment.VLRTOTAL_INTEIRO - valueDiscount;

          total += installment.VLRTOTAL_INTEIRO;
          totalWithDiscount += installmentWithDiscount;

          return {
            ...installment,
            discountPercentage: descountInstallment.discountPercentage,
            discountDescription: descountInstallment.description,
            valueDiscount,
            amountWithDiscount: installmentWithDiscount,
          };
        }
      );

    setInstallments(newInstallments);
    setAmount(total);
    setAmountWithDiscount(totalWithDiscount);
  };

  const columns = [
    {
      title: "Vencimento",
      dataIndex: "VENCORIG",
      width: "10%",
      render: (data) => {
        return formtDate(data);
      },
    },
    {
      title: "Valor original",
      dataIndex: "VALOR_INTEIRO",
      width: "10%",
      render: (data) => {
        return data.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      title: "Multa e juros",
      width: "10%",
      render: (data) => {
        return (data.VALOR_JUROS + data.VALOR_MULTA).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      title: "% Desconto",
      dataIndex: "discountPercentage",
      width: "10%",
    },
    {
      title: "Valor do desconto",
      dataIndex: "valueDiscount",
      width: "15%",
      render: (data) =>
        data.toLocaleString("pt-br", { style: "currency", currency: "BRL" }),
    },
    {
      title: "Descrição do desconto",
      dataIndex: "discountDescription",
      width: "30%",
    },
    {
      title: "Valor final",
      dataIndex: "amountWithDiscount",
      width: "15%",
      render: (data) => {
        return data.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
  ];

  async function handleTableChange(pagination) {
    setPage(pagination.current);
  }

  const handlerShowSizeChange = (current, size) => setRowsPerPage(size);


  const sortData = (data) => {
    return data.sort((a, b) => {
      return new Date(a.VENCORIG).getTime() - new Date(b.VENCORIG).getTime();
    });
  };

  const handleReturn = () => {
    negotiationContext.setCurrent(negotiationContext.current - 1);
  };

  const handleSubmit = (values) => {
    setLoading(true);
    var valor_parcelas = 0;
    var valor_encargos = 0;
    var valor_desconto = 0;
    var valor_total = 0;

    var parcelas = [];
    installments.map((installment) => {
      valor_parcelas += installment.VALOR_INTEIRO;
      valor_encargos += installment.VALOR_JUROS + installment.VALOR_MULTA;
      valor_desconto += installment.valueDiscount;
      valor_total += installment.amountWithDiscount;

      parcelas.push({
        prefixo: installment.PREFIXO,
        numero: installment.NUMERO,
        parcela: installment.PARCELA,
        tipo: installment.TIPO,
      });
    });

    const data = {
      contrato: negotiationContext.data.contract.CONTRATO,
      cliente: negotiationContext.data.contract.CODIGOCLIENTE,
      forma_pagamento: paymentType.value.toUpperCase(),
      quantidade_parcelas: installmentSelected,
      valor_parcelas: parseFloat(valor_parcelas.toFixed(2)),
      valor_encargos: parseFloat(valor_encargos.toFixed(2)),
      valor_desconto: parseFloat(valor_desconto.toFixed(2)),
      valor_total: parseFloat(valor_total.toFixed(2)),
      fatura: "",
      parcelas: parcelas,
      user: signin.user,
    };

    // console.log(data)

    duplicateBilletApi
      .gerarNegociacao(data, {
        headers: {
          "Content-Type": "application/json",
          tenantId: `${negotiationContext.data.contract.EMPRESA},${negotiationContext.data.contract.FILIAL}`,
        },
      })
      .then((res) => {
        negotiationContext.setData({
          ...negotiationContext.data,
          contract: {
            ...negotiationContext.data.contract,
            negotiation: res.data,
          },
        });

        setLoading(false);

        negotiationContext.setCurrent(negotiationContext.current + 1);
      })
      .catch((error) => {
        message.error(error);
        setLoading(false);
      });
  };

  const handleChangeSelect = (value) => {

    setInstallmentSelected(1);

    let index = negotiationContext.data.paymentType.findIndex(
      (payment) => payment.value === value
    );

    if (index >= 0) {
      setPaymentType(negotiationContext.data.paymentType[index]);

      setButtonNextDisabled(false);
    } else {
      setButtonNextDisabled(true);
    }
  };

  const handleChangeInstallments = (value) => {
    setInstallmentSelected(value)
  }


  if(negotiationContext.data.contract.TIPOCONTRT.trim() === 'C'){
    negotiationContext.data.paymentType = negotiationContext.data.paymentType.map((payment) => {
      switch (payment.value) {
        case 'pix':
          payment.maxPercent = 10
          break;
          case 'boleto':
            payment.maxPercent = 10
            break;
            case 'superlink':
              payment.maxPercent = 8
              break;
            }
            return payment
      });
  }

  if(negotiationContext.data.contract.TIPOCONTRT.trim() === 'P'){
      negotiationContext.groupFinancial = false
  }

  return (
    <Spin tip="Gerando negociação..." spinning={loading}>
      <Row gutter={[8, 8]}>
        <Col xs={24} md={12}>
          <Row gutter={[8, 8]}>
            <Col xs={12}>
              <Typography.Text>Forma de pagamento:</Typography.Text>
              <Select
                value={paymentType.value}
                style={{ width: "100%" }}
                placeholder="Selecione a forma de pagamento."
                onChange={handleChangeSelect}
              >
                {negotiationContext.data.paymentType.map((paymentType) => {
                  return (
                    <Option value={paymentType.value}>
                      <Row justify="space-between">
                        <Col>{paymentType.description}</Col>
                        <Col>{`Até ${negotiationContext.groupFinancial ? 90 : paymentType.maxPercent}%`}</Col>
                      </Row>
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col xs={12}>
              <Typography.Text>Percentual de desconto:</Typography.Text>
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Digite o percentual de desconto"
                value={descount}
                onChange={(value) => setDescount(value)}
                disabled={!paymentType}
                max={negotiationContext.groupFinancial ? 90 : paymentType.maxPercent}
                min={0}
              />
            </Col>
          </Row>
          {
            paymentType.installments && negotiationContext.data.payment_prescribed && negotiationContext.groupFinancial?
              <Row gutter={[8, 8]}>
                <Col xs={12}>
                  <Select
                    style={{ width: "100%", marginTop: '10px' }}
                    placeholder="Selecione o Parcelamento"
                    onChange={handleChangeInstallments}
                    value={installmentSelected}
                  >
                    {
                      paymentType.installments.map(number => {
                        return (
                          <Select.Option value={number}>
                            <Row justify='space-between'>
                              <Col>
                                {`${number}x`}
                              </Col>
                              <Col>
                                {parseFloat((amountWithDiscount / number).toFixed(2)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                              </Col>
                            </Row>
                          </Select.Option>
                        );
                      })
                    }
                  </Select>
                </Col>
              </Row>
              :
              <></>
          }
        </Col>
        <Col xs={24} md={12}>
          <Row justify="space-between">
            <Col xs={12} style={{ paddingLeft: "5px", paddingRight: "5px" }}>
              <CardTotal
                value={amount.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
                color="#9D9D9D"
                title="Total sem descontos:"
                loading={false}
              />
            </Col>
            <Col xs={12} style={{ paddingLeft: "5px", paddingRight: "5px" }}>
              <CardTotal
                value={amountWithDiscount.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
                color="#1890ff"
                title="Total com descontos:"
                loading={false}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={{ xs: 24, sm: 24, md: 24 }} style={{ marginTop: "20px" }}>
        <Col xs={24}>
          <Table
            row
            columns={columns}
            dataSource={sortData(installments)}
            rowKey="codigo"
            loading={loading}
            pagination={{
              current: page,
              pageSize: rowsPerPage,
              total: installments.length,
              defaultPageSize: 5,
              showSizeChanger: true,
              onShowSizeChange: handlerShowSizeChange,
              pageSizeOptions: [5, 10, 50, 100]

            }}
            onChange={handleTableChange}
            size="small"
            title={() => (
              <Row justify="center">
                <Col>
                  <Typography.Title strong level={3}>
                    Informações das parcelas
                  </Typography.Title>
                </Col>
              </Row>
            )}
            footer={() =>
              footerObs ? (
                <Typography.Text strong>
                  * Parcelas com vencimento até 01/2018 possuem desconto fixo de
                  80%.
                </Typography.Text>
              ) : (
                ""
              )
            }
          />
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Button
            style={{ margin: "0 8px" }}
            onClick={() => negotiationContext.setCurrent(0)}
          >
            Realizar nova consulta
          </Button>
        </Col>
        <Col>
          <Button style={{ margin: "0 8px" }} onClick={handleReturn}>
            Voltar
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            style={{ margin: "0 8px" }}
            onClick={handleSubmit}
            disabled={buttonNextDisabled}
          >
            Avançar
          </Button>
        </Col>
      </Row>
    </Spin>
  );
}

export default Negotiation;
