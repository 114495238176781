const styles = {
  getButtonStyle: (isSelected) => ({
    backgroundColor: isSelected ? "#1890ff" : "",
    color: isSelected ? "#fff" : "#1890ff",
    borderColor: "#1890ff",
  }),
  getFolderButtonStyle: () => ({
    color: "#002766",
    borderColor: "#002766",
    backgroundColor: "transparent",
  }),
};

export default styles;